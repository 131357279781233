import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { GetAboutMe, SaveAboutMe as abtSave } from "../../apis/ProfileAPI";
import "./MyProfile.css";
import "react-phone-number-input/style.css";
import dateFormat from "dateformat";
import { Typeahead } from "react-bootstrap-typeahead";
import { GetCountryList } from "../../apis/MiscAPI";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import { RenderText } from "../../Components/FormInputs/RenderText";
import { RenderRadioText } from "../../Components/FormInputs/RenderRadioText";
import { RenderRadio } from "../../Components/FormInputs/RenderRadio";
import { RenderTextarea } from "../../Components/FormInputs/RenderTextarea";

var changeDetected = false;

export default function AboutMe(prop) {
  const { location } = useSelector((state) => state.auth);
  const [profileData, setProfileData] = useState({
    user_id: { value: "", isInvalid: true, mode: "save" },
    user_first_name: { value: "", isInvalid: true, mode: "save" },
    user_last_name: { value: "", isInvalid: true, mode: "save" },
    user_age: { value: "", isInvalid: true, mode: "save" },
    user_email: { value: "", isInvalid: true, mode: "save" },
    user_phoneno: { value: "", isInvalid: true, mode: "save" },
    user_gender: { value: "", isInvalid: true, mode: "save" },
    user_dob: { value: "", isInvalid: true, mode: "save" },
    user_birth_place: { value: "", isInvalid: true, mode: "save" },
    user_address: { value: "", isInvalid: true, mode: "save" },
    user_city: { value: "", isInvalid: true, mode: "save" },
    userQnA: { value: [], isInvalid: false, mode: "submit" },
  });
  const [dataUpload, setDataUpload] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [countryCode, setCountryCode] = useState("");

  const defaultButtonText = {
    save: "Save",
    submit: "Submit",
  };
  const [buttonText, setButtonText] = useState(defaultButtonText);

  useEffect(() => {
    if (location != null && location != undefined)
      setCountryCode(location.countryCode);
  }, [location]);

  const feet = ["4 feet", "5 feet", "6 feet", "7 feet"];
  const inches = [
    "0 inch",
    "1 inch",
    "2 inches",
    "3 inches",
    "4 inches",
    "5 inches",
    "6 inches",
    "7 inches",
    "8 inches",
    "9 inches",
    "10 inches",
    "11 inches",
  ];

  const [disableBtn, setDisableBtn] = useState(false);

  const updateProfileAboutMeData = (
    propName,
    propValue,
    mode = "save",
    render
  ) => {
    if (render === undefined && prop.autoSaveMode.aboutme) {
      changeDetected = true;
    }
    if (profileData.hasOwnProperty(propName)) {
      setProfileData((prevState) => ({
        ...prevState,
        [propName]: {
          ...prevState[propName],
          value: propValue,
          isInvalid:
            propName === "user_phoneno" &&
            propValue !== "" &&
            propValue !== undefined
              ? !isPossiblePhoneNumber(propValue, countryCode)
              : propValue == "" || propValue == null
              ? true
              : false,
          mode: mode,
        },
      }));
    }
  };

  const updateQnA = (qn, ans, render, mode = "save") => {
    var updatedUserQnA = profileData.userQnA.value;

    const existingQIndex = updatedUserQnA.findIndex(
      (q) => q.question_id === qn.question_id
    );

    if (existingQIndex !== -1) {
      // Update existing question
      const isInitialRender = render === "initial";
      var invld = true;
      if (qn.question_id === 67) {
        var kidq = profileData.userQnA.value
          .filter((x) => x.question_id === 6)
          .map((y) => y.answer);
        if (kidq[0].value.localeCompare("No",'en', { sensitivity: 'base' }) === 0) invld = false;
        else invld = ans === "";
      }
      if(qn.question_id === 64) invld = false;
      qn.answer = {
        value: isInitialRender ? qn.answer : ans,
        isInvalid: isInitialRender
          ? qn.answer === null
            ? qn.answer === ""
            : qn.answer.value === ""
          : ans === "" && (qn.question_id === 67 || qn.question_id === 64)
          ? invld
          : ans === "",
        mode: mode,
      };
      updatedUserQnA[existingQIndex] = qn;
    } else {
      // Add new question
      qn.answer = {
        value: qn.answer,
        isInvalid: qn.question_id === 64 ? false : qn.answer === "",
        mode: mode,
      };
      updatedUserQnA.push(qn);
    }
    setDataUpload(true);
    updateProfileAboutMeData("userQnA", updatedUserQnA, render);

    if (qn.question_id == "6" && ans !== null && ans.localeCompare("No",'en', { sensitivity: 'base' }) === 0) {
      var itm = profileData.userQnA.value.filter((x) => x.question_id == 67);
      if (itm != null && itm.length > 0) {
        updateQnA(itm[0], "", null, "save");
      }
    }
  };

  const updateMode = (mode) => {
    const abtMeData = { ...profileData };

    // Update mode for userQnA
    if (abtMeData.userQnA) {
      if (Array.isArray(abtMeData.userQnA.value)) {
        // Handle array of questions
        abtMeData.userQnA.value.forEach((qn) => {
          qn.answer.mode = mode;
        });
      } else {
        // Handle single question
        abtMeData.userQnA.answer.mode = mode;
      }
    }

    // Update mode for other fields (e.g., user_first_name, user_last_name, etc.)
    for (const key in abtMeData) {
      if (key !== "userQnA") {
        abtMeData[key].mode = mode;
      }
    }

    setProfileData(abtMeData);
  };

  const GetAbtMe = async () => {
    var data = await GetAboutMe(prop.userid ?? 0);
    let basicData = data.item1;
    let aboutMeQnA = data.item2;

    prop.setProfileName(
      basicData.user_first_name + " " + basicData.user_last_name,
      basicData.user_id
    );

    for (var propty in basicData) {
      if (!basicData.hasOwnProperty(propty)) continue;
      updateProfileAboutMeData(propty, basicData[propty], "save", "initial");
    }
    aboutMeQnA.forEach((element) => {
      updateQnA(element, null, "initial");
      if (element.field_type === "select") {
        if (element.answer.value != "" && element.answer.value != null) {
          var ids = element.answer.value.split(",");
          var filterdCountries = countries.filter((x) =>
            ids.includes(x.country_id.toString())
          );
          setSelectedCountries(filterdCountries);
        }
      }
    });
  };

  const GetCountries = async () => {
    var data = await GetCountryList();
    data.unshift({ country_id: 0, name: "NA" });
    setCountries(data);
  };

  useEffect(() => {
    GetCountries();
    setButtonText(defaultButtonText);
  }, []);

  useEffect(() => {
    if (countries.length > 0) GetAbtMe();
  }, [countries]);

  useEffect(() => {
    if (dataUpload) {
      var elem = document
        .getElementsByClassName("accordion")[0]
        .querySelector(".accordion-collapse");
      prop.viewmodefunc(elem);
    }
  }, [dataUpload]);

  useEffect(() => {
    if (prop.autoSaveMode.aboutme) {
      var id = setInterval(autoSaveData, 2 * 60 * 1000);
      setIntervalId(id);
    } else {
      clearInterval(intervalId);
    }
  }, [prop.autoSaveMode.aboutme]);

  function autoSaveData() {
    if (changeDetected && profileData.user_id !== null) {
      saveAboutMe("save", "auto");
      setButtonText((prevState) => ({ ...prevState, save: "Auto Saving..." }));
    }
  }

  const handleHeight = (item, v) => {
    var hght =
      item.answer.value == null ? [0, 0] : item.answer.value.split(".");
    if (v.indexOf("feet") !== -1) hght[0] = v.substring(0, v.indexOf("f") - 1);
    if (v.indexOf("inch") !== -1) hght[1] = v.substring(0, v.indexOf("i") - 1);

    var ans = `${hght[0]}.${hght[1]}`;
    updateQnA(item, ans, null);
  };

  const saveAboutMe = async (mode, type) => {
    if (type !== "auto")
      setButtonText((prevState) => ({
        ...prevState,
        [mode]: mode === "save" ? "Saving..." : "Submitting...",
      }));

    setDisableBtn(true);
    var qna = [];
    profileData.userQnA.value.forEach((item) => {
      qna.push({ QuestionId: item.question_id, Answer: item.answer.value });
    });
    const abtMe = {
      userId: parseInt(profileData.user_id.value),
      firstName: profileData.user_first_name.value,
      lastName: profileData.user_last_name.value,
      phoneNo: profileData.user_phoneno.value,
      gender: profileData.user_gender.value,
      birthPlace: profileData.user_birth_place.value,
      address: profileData.user_address.value,
      city: profileData.user_city.value,
      qnA: qna,
      mode: mode === "save" ? 0 : 1,
    };

    if (mode === "save") {
      updateMode("save");
      await abtSave(abtMe);
      setButtonText((prevState) => ({
        ...prevState,
        [mode]: "Saved",
      }));
      setDisableBtn(false);
      setDefaultButtonText();
    } else if (mode === "submit") {
      updateMode("submit");
      if (isObjectValid(profileData)) {
        await abtSave(abtMe);
        setButtonText((prevState) => ({
          ...prevState,
          [mode]: "Submitted",
        }));
        setDefaultButtonText();
      } else {
        setButtonText((prevState) => ({
          ...prevState,
          [mode]: "Fill all the fields.",
        }));
        setDefaultButtonText(5000);
      }
      setDisableBtn(false);
    }
  };

  const isObjectValid = (obj) => {
    console.log(obj);
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key === "userQnA") {
          const qns = obj[key].value;
          for (const q in qns) {
            if (qns.hasOwnProperty(q)) {
              if (qns[q].answer.isInvalid && qns[q].question_id != 64) {
                if (qns[q].question_id === 67) {
                  var kidq = qns
                    .filter((x) => x.question_id === 6)
                    .map((y) => y.answer.value);
                  console.log(kidq);
                  if (kidq[0].indexOf("Yes") !== -1 && qns[q].answer.isInvalid)
                    return false;
                } else return false;
              }
            }
          }
        } else {
          if (obj[key] !== "user_image" && obj[key].isInvalid) {
            return false; // Invalid value found
          }
        }
      }
    }
    return true; // All properties have valid values
  };

  const handleCountryChange = (item, selectedOptions) => {
    console.log(selectedOptions);
    if (selectedOptions.length > 0) {
      var naOpt = selectedOptions.filter((x) => x.country_id === 0);
      if (naOpt != null && naOpt.length > 0) selectedOptions = naOpt;
      setSelectedCountries(selectedOptions);
      var ids = selectedOptions.map((item) => item.country_id);
      var joined_ids = ids.join(",");
      updateQnA(item, joined_ids, null);
    } else {
      setSelectedCountries([]); // Clear selection if no option is selected
      updateQnA(item, "", null); // Clear country in answer
    }
  };

  const setDefaultButtonText = (t = 3000) => {
    changeDetected = false;
    setTimeout(() => {
      setButtonText(defaultButtonText);
    }, t);
  };

  return (
    <div className="my-profile-info">
      <Form>
        <Form.Group
          className="my-profile-info-form-row"
          controlId="Email"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email"
            disabled
            className="non-edit"
            value={profileData.user_email.value}
          />
        </Form.Group>
        <Form.Group
          className="my-profile-info-form-row full-width-input"
          controlId="ContactNumber"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column>Contact Number</Form.Label>
          <PhoneInput
            international
            countryCallingCodeEditable={false}
            defaultCountry="IN"
            placeholder="Enter phone number"
            value={profileData.user_phoneno.value}
            onChange={(e) => updateProfileAboutMeData("user_phoneno", e)}
            rules={{ required: true }}
          />
          {profileData.user_phoneno.mode !== "save" &&
            profileData.user_phoneno.isInvalid && (
              <Form.Text className="text-danger">
                Please provide valid phone number
              </Form.Text>
            )}
        </Form.Group>
        <Form.Group
          className="my-profile-info-form-row"
          controlId="UserAge"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column>Age</Form.Label>
          <Form.Control
            type="text"
            placeholder="Age"
            value={profileData.user_age.value}
            className="non-edit"
            disabled
          />
        </Form.Group>
        <Form.Group
          className="my-profile-info-form-row"
          controlId="DateOBirth"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column>Date of Birth</Form.Label>
          <Form.Control
            type="text"
            placeholder="Date of Birth"
            disabled
            className="non-edit"
            value={dateFormat(profileData.user_dob.value, "mmm dS, yyyy")}
          />
        </Form.Group>
        <Form.Group
          className="my-profile-info-form-row"
          controlId="PlaceBirth"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column>Place of Birth</Form.Label>
          <Form.Control
            type="text"
            placeholder="Place of Birth"
            isInvalid={
              profileData.user_birth_place.isInvalid &&
              profileData.user_birth_place.mode !== "save"
            }
            value={profileData.user_birth_place.value ?? ""}
            onChange={(e) =>
              updateProfileAboutMeData("user_birth_place", e.target.value)
            }
          />
          <Form.Control.Feedback type="invalid">
            Please fill place of birth
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          className="my-profile-info-form-row"
          controlId="Gender"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column>I am a</Form.Label>
          <div className="radios-btns-gender">
            <Form.Check inline type="radio" name="Gender" id="male">
              <Form.Check.Input
                type="radio"
                value="male"
                checked={profileData.user_gender.value == "male"}
                onChange={(e) =>
                  updateProfileAboutMeData("user_gender", e.target.value)
                }
              />
              <Form.Check.Label>Man</Form.Check.Label>
            </Form.Check>
            <Form.Check inline type="radio" name="Gender" id="female">
              <Form.Check.Input
                type="radio"
                value="female"
                checked={profileData.user_gender.value == "female"}
                onChange={(e) =>
                  updateProfileAboutMeData("user_gender", e.target.value)
                }
                isInvalid={
                  profileData.user_gender.isInvalid &&
                  profileData.user_gender.mode !== "save"
                }
              />
              <Form.Check.Label>Woman</Form.Check.Label>
              <Form.Control.Feedback type="invalid">
                Please fill gender
              </Form.Control.Feedback>
            </Form.Check>
          </div>
        </Form.Group>
        <Form.Group
          className="my-profile-info-form-row full-width-input"
          controlId="CurrentResidentialAddress"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column>Location</Form.Label>
          <Form.Control
            type="text"
            placeholder="Location"
            isInvalid={
              profileData.user_address.isInvalid &&
              profileData.user_address.mode !== "save"
            }
            value={profileData.user_address.value}
            onChange={(e) =>
              updateProfileAboutMeData("user_address", e.target.value)
            }
          />
          <Form.Control.Feedback type="invalid">
            Please fill address
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          className="my-profile-info-form-row"
          controlId="UserCity"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column>City currently living in</Form.Label>
          <Form.Control
            type="text"
            placeholder="City"
            value={profileData.user_city.value}
            className="non-edit"
            disabled
          />
        </Form.Group>
        {profileData.userQnA.value.map((item, indx) =>
          item.field_type === "text" ? (
            <RenderText item={item} updateQnA={updateQnA} />
          ) : item.field_type === "select_2" ? (
            <Form.Group
              className="my-profile-info-form-row"
              controlId={"Q_" + item.question_id}
              key={"QS2_" + item.question_id}
            >
              <Form.Label column>{item.question}</Form.Label>
              <Form.Select
                aria-label={item.question}
                size="sm"
                value={
                  item.answer.value == null
                    ? ""
                    : `${item.answer.value.split(".")[0]} feet`
                }
                onChange={(e) => handleHeight(item, e.target.value)}
                isInvalid={item.answer.isInvalid && item.answer.mode !== "save"}
              >
                <option key="feet">Feet</option>
                {feet.map((f, i) => (
                  <option key={"QSF_" + i}>{f}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please fill this field
              </Form.Control.Feedback>{" "}
              <Form.Select
                aria-label={item.question}
                size="sm"
                value={
                  item.answer.value == null
                    ? ""
                    : item.answer.value.split(".")[1] == 0 ||
                      item.answer.value.split(".")[1] == 1
                    ? `${item.answer.value.split(".")[1]} inch`
                    : `${item.answer.value.split(".")[1]} inches`
                }
                onChange={(e) => handleHeight(item, e.target.value)}
                isInvalid={item.answer.isInvalid && item.answer.mode !== "save"}
              >
                <option key="inch">Inches</option>
                {inches.map((f, i) => (
                  <option key={"QSI_" + i}>{f}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please fill this field
              </Form.Control.Feedback>
            </Form.Group>
          ) : item.field_type === "radio_text" ? (
            <RenderRadioText item={item} updateQnA={updateQnA} />
          ) : item.field_type === "textarea" ? (
            <RenderTextarea item={item} updateQnA={updateQnA} />
          ) : item.field_type === "radio" ? (
            <RenderRadio item={item} updateQnA={updateQnA} />
          ) : item.field_type === "select" ? (
            <Form.Group
              key={"QTA_" + item.question_id}
              className="my-profile-info-form-row full-width-input"
              controlId={"QTA_" + item.question_id}
            >
              <Form.Label column>{item.question}</Form.Label>
              <Typeahead
                multiple
                id="membership_country"
                labelKey="name"
                options={countries}
                placeholder={item.question}
                selected={selectedCountries}
                isInvalid={item.answer.isInvalid && item.answer.mode !== "save"}
                onChange={(e) => handleCountryChange(item, e)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide name of the country you lived in
              </Form.Control.Feedback>
            </Form.Group>
          ) : item.field_type === "radio_textarea_radio" ? (
            <>
              <Form.Group
                key={"QRT_" + item.question_id}
                className="my-profile-info-form-row full-width-input"
                controlId={"Q_" + item.question_id}
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <Form.Label column>{item.question}</Form.Label>
                <div className="radios-btns-gender">
                  {item.option != null &&
                    item.option.split("~").map((o, i) => (
                      <div key={`RT_${i}_${item.question_id}`}>
                        {item.option.split("~").length - 1 === i ? (
                          <Form.Check
                            inline
                            name={`qo${i}${item.question_id}`}
                            id={`${i}${item.question_id}`}
                          >
                            <Form.Check.Input
                              type="radio"
                              value={o.split("^")[0]}
                              checked={
                                (item.answer.value != null &&
                                  item.answer.value == o.split("^")[0]) ||
                                (item.answer.value != null &&
                                  item.answer.value.indexOf("^") !== -1 &&
                                  item.answer.value.indexOf(
                                    o.split("^")[0].trim()
                                  ) !== -1)
                              }
                              onChange={(e) =>
                                updateQnA(item, e.target.value, null)
                              }
                              isInvalid={
                                item.answer.isInvalid &&
                                item.answer.mode !== "save"
                              }
                            />
                            <Form.Check.Label>
                              {o.split("^")[0]}
                            </Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                              Please fill this field
                            </Form.Control.Feedback>
                          </Form.Check>
                        ) : (
                          <Form.Check
                            inline
                            name={`qo${i}${item.question_id}`}
                            id={`${i}${item.question_id}`}
                          >
                            <Form.Check.Input
                              type="radio"
                              value={o.split("^")[0]}
                              checked={
                                (item.answer.value != null &&
                                  item.answer.value == o.split("^")[0]) ||
                                (item.answer.value != null &&
                                  item.answer.value.indexOf("^") !== -1 &&
                                  item.answer.value.indexOf("Yes") !== -1)
                              }
                              onChange={(e) =>
                                updateQnA(item, e.target.value, null)
                              }
                            />
                            <Form.Check.Label>
                              {o.split("^")[0]}
                            </Form.Check.Label>
                          </Form.Check>
                        )}
                      </div>
                    ))}
                </div>
                {item.answer.value != null &&
                  item.answer.value.indexOf("Yes") !== -1 && (
                    <>
                      <Form.Control
                        as="textarea"
                        placeholder={item.question}
                        rows={3}
                        value={
                          item.answer.value == null
                            ? ""
                            : item.answer.value.split("^")[1]
                        }
                        onChange={(e) =>
                          updateQnA(item, "Yes^" + e.target.value, null)
                        }
                        isInvalid={
                          item.answer.isInvalid && item.answer.mode !== "save"
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please fill this field
                      </Form.Control.Feedback>
                    </>
                  )}
              </Form.Group>
              {item.answer.value != null &&
                item.answer.value.indexOf("Yes") !== -1 && (
                  <Form.Group
                    key={"Q_" + item.option.split("^")[1]}
                    className="my-profile-info-form-row full-width-input"
                    controlId={"Q_" + item.option.split("^")[1]}
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <Form.Label column key={"QL_" + item.option.split("^")[1]}>
                      {
                        profileData.userQnA.value.filter(
                          (x) =>
                            x.question_id.toString() ===
                            item.option.split("^")[1]
                        )[0].question
                      }
                    </Form.Label>
                    <div className="radios-btns-gender">
                      {profileData.userQnA.value.filter(
                        (x) =>
                          x.question_id.toString() === item.option.split("^")[1]
                      )[0].option != null &&
                        profileData.userQnA.value
                          .filter(
                            (x) =>
                              x.question_id.toString() ===
                              item.option.split("^")[1]
                          )[0]
                          .option.split("~")
                          .map((o, i) => (
                            <div key={`QR_${i}_${item.option.split("^")[1]}`}>
                              {item.option.split("~").length - 1 === i ? (
                                <Form.Check
                                  inline
                                  name={`qo${i}${item.option.split("^")[1]}`}
                                  id={`${i}${item.option.split("^")[1]}`}
                                >
                                  <Form.Check.Input
                                    type="radio"
                                    value={o}
                                    checked={
                                      profileData.userQnA.value.filter(
                                        (x) =>
                                          x.question_id.toString() ===
                                          item.option.split("^")[1]
                                      )[0].answer.value != null &&
                                      profileData.userQnA.value.filter(
                                        (x) =>
                                          x.question_id.toString() ===
                                          item.option.split("^")[1]
                                      )[0].answer.value == o
                                    }
                                    onChange={(e) =>
                                      updateQnA(
                                        profileData.userQnA.value.filter(
                                          (x) =>
                                            x.question_id.toString() ===
                                            item.option.split("^")[1]
                                        )[0],
                                        e.target.value
                                      )
                                    }
                                    isInvalid={
                                      profileData.userQnA.value.filter(
                                        (x) =>
                                          x.question_id.toString() ===
                                          item.option.split("^")[1]
                                      )[0].answer.isInvalid &&
                                      profileData.userQnA.value.filter(
                                        (x) =>
                                          x.question_id.toString() ===
                                          item.option.split("^")[1]
                                      )[0].answer.mode !== "save"
                                    }
                                  />
                                  <Form.Check.Label>{o}</Form.Check.Label>
                                  <Form.Control.Feedback type="invalid">
                                    Please fill this field
                                  </Form.Control.Feedback>
                                </Form.Check>
                              ) : (
                                <Form.Check
                                  inline
                                  name={`qo${i}${item.option.split("^")[1]}`}
                                  id={`${i}${item.option.split("^")[1]}`}
                                >
                                  <Form.Check.Input
                                    type="radio"
                                    value={o}
                                    checked={
                                      profileData.userQnA.value.filter(
                                        (x) =>
                                          x.question_id.toString() ===
                                          item.option.split("^")[1]
                                      )[0].answer.value != null &&
                                      profileData.userQnA.value.filter(
                                        (x) =>
                                          x.question_id.toString() ===
                                          item.option.split("^")[1]
                                      )[0].answer.value == o
                                    }
                                    onChange={(e) =>
                                      updateQnA(
                                        profileData.userQnA.value.filter(
                                          (x) =>
                                            x.question_id.toString() ===
                                            item.option.split("^")[1]
                                        )[0],
                                        e.target.value
                                      )
                                    }
                                  />
                                  <Form.Check.Label>{o}</Form.Check.Label>
                                </Form.Check>
                              )}
                            </div>
                          ))}
                    </div>
                  </Form.Group>
                )}
            </>
          ) : (
            ""
          )
        )}
        {!prop.userid && (
          <div className="submit-action-btns">
            <Button
              type="button"
              size="sm"
              data-aos="fade-up"
              data-aos-duration="1000"
              className="join-btn btn btn-primary aos-init aos-animate"
              onClick={() => saveAboutMe("save")}
              disabled={disableBtn}
            >
              {buttonText.save}
            </Button>{" "}
            <Button
              type="button"
              size="sm"
              data-aos="fade-up"
              data-aos-duration="1000"
              className="join-btn btn btn-primary aos-init aos-animate"
              onClick={() => saveAboutMe("submit")}
              disabled={disableBtn}
            >
              {buttonText.submit}
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
}
